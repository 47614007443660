<template>
  <div>
    <el-row :gutter="24">
      <el-col :span="6" >
        <LeftMenu class="leftmenu"/>
      </el-col>
      <el-col :span="18">
        <el-row :gutter="24">
          <el-col
            :span="6"
            v-for="product in products"
            :key="product.ProductID"
            style="margin-top:20px;"
          >
            <el-card shadow="hover">
              <img
                :src="$store.state.baseurl+'/Images/'+product.ProductImgUrl.split(' ')[0]"
                class="image"
              />
              <div style="padding: 14px;">
                <p class="content">{{$i18n.locale == 'zh-cn'? product.ProductNameChn: product.ProductNameEng}}</p>
                <p class="content">{{$i18n.locale == 'zh-cn'? product.ProductSizeChn: product.ProductSizeEng}}</p>
                <div style="margin-top: 12px;">
                  <el-button type="warning" @click="handleClick(product.ProductID)">{{$t('home.details')}}</el-button>
                </div>
              </div>
            </el-card>
          </el-col>
        </el-row>
      </el-col>
    </el-row>
  </div>
</template>
<script>
import LeftMenu from '@/components/LeftMenu.vue'
export default {
  name: 'Product',
  data() {
    return {
      products: []
    }
  },
  components: {
    LeftMenu
  },
  methods: {
    handleClick(n) {
      this.$router.push('/product/' + n)
    },
    getproducts(cid){
      this.$http
        .get('/product/GetProducts?cid='+cid)
        .then(res => (this.products = res.data.data))
    }
  },
  created() {
      this.getproducts(this.$route.params.cid);
  },
  beforeRouteUpdate(to, from, next){
    //使用Vue的动态路由参数来复用组件可以减少Vue的运行开销，
    //页面渲染更加高效,但是因为Vue复用了组件，
    //所有路由跳转后Vue的声明周期钩子不再重新调用了。这样会导致我们写在mounted或created函数中的一些页面初始化代码无效
    //所以一定要用这个导航守卫在路由更改时重新获取数据
    this.getproducts(to.params.cid)
    next();
  }
}
</script>
<style scoped>
.image {
  width: 98%;
  display: block;
}

.el-pagination .is-background .el-pager li:not(.disabled) .active {
  background-color: #ffa500 !important;
}

.content{
  width: 95%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}
.leftmenu{
  height: 100%;
}
</style>
